﻿export default {
    English: {
        "login.title": "Sign in to your account",
        "login.email": "Email address *",
        "login.password": "Password *",
        "login.error": "Invalid email or password",
        "login.signin": "Sign in",
        "login.theme": "Theme",
        "login.password.placeholder": "Your Password",
        "login.logout": "Logout",
        "menu.title": "Experiential AI: Embrace Immersive Learning",
        "menu.description": "Step into a new phase of learning, where practice is the key. Engage in captivating experiences to expand your understanding, safely role- play, and apply your skills. Immerse yourself in interactive learning driven by hands- on practice and a passion for growth.",
        "chat.conversation": "Conversation",
        "chat.newChat": "New Chat",
        "chat.unfortunately": "Unfortunately, you have not successfully completed the simulation.",
        "chat.congratulations": "Congratulations! You have successfully completed the simulation, review the conversation for feedback and tips on where you can improve further, alternatively try the simulation again",
        "stage.notstarted": "Not Started",
        "stage.completed": "Completed",
        "stage.inprogress": "In Progress",
        "stage.failed": "Failed",
        "stage.finish": "Finish",
        "stage.success": "Success",
        "stage.inform": "Information Gathering",

    }
    //French: {
    //    "login.title": "Connectez-vous à votre compte",
    //    "login.email": "Adresse e-mail *",
    //    "login.password": "Mot de passe *",
    //    "login.error": "Adresse e-mail ou mot de passe invalide",
    //    "login.signin": "Se connecter",
    //    "login.theme": "Thème",
    //    "login.password.placeholder": "Votre mot de passe",
    //    "login.logout": "Se déconnecter",
    //    "menu.title": "IA expérientielle: adoptez l'apprentissage immersif",
    //    "menu.description": "Entrez dans une nouvelle phase d'apprentissage, où la pratique est la clé. Plongez dans des expériences captivantes pour élargir votre compréhension, jouez en toute sécurité et appliquez vos compétences. Plongez-vous dans un apprentissage interactif basé sur la pratique et une passion pour la croissance.",
    //    "chat.conversation": "Conversation",
    //    "chat.newChat": "Nouveau chat",
    //    "chat.unfortunately": "Malheureusement, vous n'avez pas réussi à terminer avec succès le tour de qualification. Veuillez recommencer et essayer d'en apprendre le plus possible sur Charlie, comme ce qu'il aime sur sa moto, ses intérêts en matière de conduite et ses motivations d'achat. Votre objectif est de fournir suffisamment de valeur pour qu'il accepte de regarder une nouvelle moto 2024.",
    //    "chat.congratulations": "Félicitations! Vous avez réussi à terminer avec succès le tour de qualification et êtes éligible pour passer au tour préliminaire. Veuillez vous souvenir de ce que vous avez appris sur Charlie afin de pouvoir utiliser les informations lorsque vous continuez dans la compétition.",
    //    "stage.notstarted": "Pas commencé",
    //    "stage.completed": "Terminé",
    //    "stage.inprogress": "En cours",
    //    "stage.failed": "Échoué",
    //    "stage.finish": "Terminer",
    //    "stage.success": "Succès",
    //    "stage.inform": "Collecte d'informations",
    //},
    //Spanish: {
    //    "login.title": "Inicia sesión en tu cuenta",
    //    "login.email": "Dirección de correo electrónico *",
    //    "login.password": "Contraseña *",
    //    "login.error": "Correo electrónico o contraseña no válidos",
    //    "login.signin": "Iniciar sesión",
    //    "login.theme": "Tema",
    //    "login.password.placeholder": "Tu contraseña",
    //    "login.logout": "Cerrar sesión",
    //    "menu.title": "IA experiencial: abraza el aprendizaje inmersivo",
    //    "menu.description": "Ingresa a una nueva fase de aprendizaje, donde la práctica es la clave. Participa en experiencias cautivadoras para ampliar tu comprensión, juega de manera segura y aplica tus habilidades. Sumérgete en un aprendizaje interactivo basado en la práctica y una pasión por el crecimiento.",
    //    "chat.conversation": "Conversación",
    //    "chat.newChat": "Nuevo chat",
    //    "chat.unfortunately": "Desafortunadamente, no has completado con éxito la Ronda de Clasificación. Por favor, comienza de nuevo e intenta aprender tanto como puedas sobre Charlie, como lo que le gusta de su motocicleta, sus intereses en la conducción y sus motivaciones de compra. Tu objetivo es proporcionar suficiente valor para que acepte ver una nueva motocicleta 2024.",
    //    "chat.congratulations": "¡Felicidades! Has completado con éxito la Ronda de Clasificación y eres elegible para pasar a la Ronda Preliminar. Por favor, recuerda lo que aprendiste sobre Charlie para que puedas usar la información a medida que continúas en la competencia.",
    //    "stage.notstarted": "No iniciado",
    //    "stage.completed": "Completado",
    //    "stage.inprogress": "En progreso",
    //    "stage.failed": "Fallido",
    //    "stage.finish": "Terminar",
    //    "stage.success": "Éxito",
    //    "stage.inform": "Recopilación de información",
    //},
    //Portuguese: {
    //    "login.title": "Faça login na sua conta",
    //    "login.email": "Endereço de e-mail *",
    //    "login.password": "Senha *",
    //    "login.error": "E-mail ou senha inválidos",
    //    "login.signin": "Entrar",
    //    "login.theme": "Tema",
    //    "login.password.placeholder": "Sua senha",
    //    "login.logout": "Sair",
    //    "menu.title": "IA Experiencial: Abraçar a Aprendizagem Imersiva",
    //    "menu.description": "Entre em uma nova fase de aprendizado, onde a prática é a chave. Participe de experiências cativantes para expandir sua compreensão, jogue com segurança e aplique suas habilidades. Mergulhe em um aprendizado interativo baseado na prática e paixão pelo crescimento.",
    //    "chat.conversation": "Conversa",
    //    "chat.newChat": "Nova conversa",
    //    "chat.unfortunately": "Infelizmente, você não concluiu com sucesso a Rodada de Qualificação. Por favor, comece de novo e tente aprender o máximo possível sobre Charlie, como o que ele gosta em sua motocicleta, seus interesses em pilotar e suas motivações de compra. Seu objetivo é fornecer valor suficiente para que ele concorde em ver uma nova motocicleta 2024.",
    //    "chat.congratulations": "Parabéns! Você concluiu com sucesso a Rodada de Qualificação e está elegível para avançar para a Rodada Preliminar. Por favor, lembre-se do que aprendeu sobre Charlie para que possa usar as informações à medida que continua na competição.",
    //    "stage.notstarted": "Não Iniciado",
    //    "stage.completed": "Concluído",
    //    "stage.inprogress": "Em Progresso",
    //    "stage.failed": "Falhou",
    //    "stage.finish": "Terminar",
    //    "stage.success": "Sucesso",
    //    "stage.inform": "Coleta de Informações",
    //},
    //Italian: {
    //    "login.title": "Accedi al tuo account",
    //    "login.email": "Indirizzo email *",
    //    "login.password": "Password *",
    //    "login.error": "Email o password non valide",
    //    "login.signin": "Accedi",
    //    "login.theme": "Tema",
    //    "login.password.placeholder": "La tua password",
    //    "login.logout": "Disconnettersi",
    //    "menu.title": "IA esperienziale: abbraccia l'apprendimento immersivo",
    //    "menu.description": "Entra in una nuova fase di apprendimento, dove la pratica è la chiave. Partecipa a esperienze coinvolgenti per ampliare la tua comprensione, gioca in modo sicuro e applica le tue abilità. Immergiti in un apprendimento interattivo basato sulla pratica e sulla passione per la crescita.",
    //    "chat.conversation": "Conversazione",
    //    "chat.newChat": "Nuova chat",
    //    "chat.unfortunately": "Sfortunatamente, non hai completato con successo il Round di Qualifica. Per favore, ricomincia e cerca di imparare il più possibile su Charlie, come ciò che gli piace della sua moto, i suoi interessi nella guida e le sue motivazioni all'acquisto. Il tuo obiettivo è fornire abbastanza valore in modo che accetti di guardare una nuova moto 2024.",
    //    "chat.congratulations": "Congratulazioni! Hai completato con successo il Round di Qualifica e sei idoneo a passare al Round Preliminare. Ricorda ciò che hai imparato su Charlie in modo da poter utilizzare le informazioni mentre continui nella competizione.",
    //    "stage.notstarted": "Non Iniziato",
    //    "stage.completed": "Completato",
    //    "stage.inprogress": "In Corso",
    //    "stage.failed": "Fallito",
    //    "stage.finish": "Finire",
    //    "stage.success": "Successo",
    //    "stage.inform": "Raccolta di Informazioni",
    //},
    //German: {
    //    "login.title": "Melden Sie sich bei Ihrem Konto an",
    //    "login.email": "E-Mail-Adresse *",
    //    "login.password": "Passwort *",
    //    "login.error": "Ungültige E-Mail-Adresse oder Passwort",
    //    "login.signin": "Anmelden",
    //    "login.theme": "Thema",
    //    "login.password.placeholder": "Ihr Passwort",
    //    "login.logout": "Ausloggen",
    //    "menu.title": "Erlebnis-KI: Eintauchen in das immersive Lernen",
    //    "menu.description": "Betreten Sie eine neue Phase des Lernens, in der die Praxis der Schlüssel ist. Tauchen Sie ein in fesselnde Erfahrungen, um Ihr Verständnis zu erweitern, sicher zu spielen und Ihre Fähigkeiten anzuwenden. Tauchen Sie ein in interaktives Lernen, das von praktischer Übung und Leidenschaft für Wachstum getrieben wird.",
    //    "chat.conversation": "Gespräch",
    //    "chat.newChat": "Neuer Chat",
    //    "chat.unfortunately": "Leider haben Sie die Qualifikationsrunde nicht erfolgreich abgeschlossen. Bitte beginnen Sie erneut und versuchen Sie, so viel wie möglich über Charlie zu erfahren, wie zum Beispiel, was ihm an seinem Motorrad gefällt, seine Fahrinteressen und seine Kaufmotivationen. Ihr Ziel ist es, genug Wert zu bieten, damit er sich bereit erklärt, sich ein neues Motorrad 2024 anzusehen.",
    //    "chat.congratulations": "Herzlichen Glückwunsch! Sie haben die Qualifikationsrunde erfolgreich abgeschlossen und sind berechtigt, zur Vorläufigen Runde überzugehen. Bitte denken Sie daran, was Sie über Charlie gelernt haben, damit Sie die Informationen verwenden können, während Sie am Wettbewerb teilnehmen.",
    //    "stage.notstarted": "Nicht begonnen",
    //    "stage.completed": "Abgeschlossen",
    //    "stage.inprogress": "In Bearbeitung",
    //    "stage.failed": "Gescheitert",
    //    "stage.finish": "Beenden",
    //    "stage.success": "Erfolg",
    //    "stage.inform": "Informationssammlung",
    //},
    //Korean: {
    //    "login.title": "계정에 로그인",
    //    "login.email": "이메일 주소 *",
    //    "login.password": "암호 *",
    //    "login.error": "잘못된 이메일 또는 암",
    //    "login.signin": "로그인",
    //    "login.theme": "테마",
    //    "login.password.placeholder": "비밀번호",
    //    "login.logout": "로그 아웃",
    //    "menu.title": "체험형 AI: 몰입형 학습 채택",
    //    "menu.description": "실습이 핵심인 새로운 학습 단계로 들어가세요. 이해를 확장하기 위해 매력적인 경험에 참여하고 안전하게 롤플레이하고 기술을 적용하세요. 실습과 성장에 대한 열정에 기반한 대화형 학습에 몰입하세요.",
    //    "chat.conversation": "대화",
    //    "chat.newChat": "새로운 대화",
    //    "chat.unfortunately": "안타깝게도, 예선 라운드를 성공적으로 완료하지 못했습니다. 다시 시작하고, 그가 자신의 오토바이에서 좋아하는 것, 그의 라이딩 관심사 및 구매 동기 등에 대해 최대한 많이 배우려고 노력하십시오. 그의 새로운 2024 오토바이를 보기로 동의할만큼의 가치를 제공하는 것이 목표입니다.",
    //    "chat.congratulations": "축하합니다! 예선 라운드를 성공적으로 완료하셨으며 본선 라운드로 진출할 자격이 있습니다. 대회를 계속하면서 찰리에 대해 배운 내용을 기억해 사용할 수 있도록 해주십시오.",
    //    "stage.notstarted": "시작되지 않음",
    //    "stage.completed": "완료됨",
    //    "stage.inprogress": "진행 중",
    //    "stage.failed": "실패함",
    //    "stage.finish": "완료",
    //    "stage.success": "성공",
    //    "stage.inform": "정보 수집",
    //},
    //Thai: {
    //    "login.title": "เข้าสู่ระบบบัญชีของคุณ",
    //    "login.email": "ที่อยู่อีเมล *",
    //    "login.password": "รหัสผ่าน *",
    //    "login.error": "อีเมลหรือรหัสผ่านไม่ถูกต้อง",
    //    "login.signin": "เข้าสู่ระบบ",
    //    "login.theme": "ธีม",
    //    "login.password.placeholder": "รหัสผ่านของคุณ",
    //    "login.logout": "ออกจากระบบ",
    //    "menu.title": "AI ประสบการณ์: ยอมรับการเรียนรู้แบบมีสมอง",
    //    "menu.description": "เข้าสู่ระยะการเรียนรู้ใหม่ที่ฝึกฝนเป็นสิ่งสำคัญ มุ่งมั่นในประสบการณ์ที่น่าตื่นตาตื่นใจเพื่อขยายความเข้าใจของคุณ สนุกไปกับประสบการณ์ที่น่าตื่นตาตื่นใจเพื่อขยายความเข้าใจของคุณ สนุกไปกับประสบการณ์ที่น่าตื่นตา",
    //    "chat.conversation": "การสนทนา",
    //    "chat.newChat": "แชทใหม่",
    //    "chat.unfortunately": "น่าเสียดายที่คุณไม่สามารถเสร็จสิ้นรอบคัดเลือกได้ กรุณาเริ่มต้นอีกครั้งและพยายามเรียนรู้เกี่ยวกับ Charlie มากที่สุดเท่าที่จะเป็นไปได้ เช่น สิ่งที่เขาชอบในมอเตอร์ไซค์ของเขา ความสนใจในการขับขี่ของเขาและแรงจูงใจในการซื้อของเขา จุดมุ่งหมายของคุณคือการให้ค่ามากพอที่เขาจะเห็นคุณควรดูมอเตอร์ไซค์ใหม่ 2024",
    //    "chat.congratulations": "ยินดีด้วย! คุณได้เสร็จสิ้นรอบคัดเลือกอย่างสำเร็จและมีสิทธิ์ไปสู่รอบคัดเลือก โปรดจำสิ่งที่คุณเรียนรู้เกี่ยวกับ Charlie เพื่อให้คุณสามารถใช้ข้อมูลเมื่อคุณดำเนินการต่อในการแข่งขัน",
    //    "stage.notstarted": "ยังไม่เริ่ม",
    //    "stage.completed": "เสร็จสิ้น",
    //    "stage.inprogress": "กำลังดำเนินการ",
    //    "stage.failed": "ล้มเหลว",
    //    "stage.finish": "เสร็จสิ้น",
    //    "stage.success": "สำเร็จ",
    //    "stage.inform": "การรวบรวมข้อมูล",
    //},
    //Japanese: {
    //    "login.title": "アカウントにサインイン",
    //    "login.email": "メールアドレス *",
    //    "login.password": "パスワード *",
    //    "login.error": "無効なメールアドレスまたはパスワード",
    //    "login.signin": "サインイン",
    //    "login.theme": "テーマ",
    //    "login.password.placeholder": "パスワード",
    //    "login.logout": "ログアウト",
    //    "menu.title": "体験型AI：没入型学習を取り入れる",
    //    "menu.description": "実践が鍵となる新しい学習段階に入ります。理解を広げるために魅力的な体験に参加し、安全にロールプレイし、スキルを適用します。実践と成長への情熱に基づく対話型学習に没入してください。",
    //    "chat.conversation": "会話",
    //    "chat.newChat": "新しいチャット",
    //    "chat.unfortunately": "残念ながら、予選ラウンドを成功裏に完了することができませんでした。もう一度始めて、Charlieについてできるだけ多く学ぶようにしてください。彼のバイクの好きな点、彼のライディング興味、彼の購入動機など。あなたの目標は、彼が新しい2024年のバイクを見ることに同意するだけの価値を提供することです。",
    //    "chat.congratulations": "おめでとうございます！予選ラウンドを成功裏に完了し、予選ラウンドに進む資格があります。競技を続けながらCharlieについて学んだことを覚えて、競技を続ける際に情報を使用できるようにしてください。",
    //    "stage.notstarted": "開始されていません",
    //    "stage.completed": "完了",
    //    "stage.inprogress": "進行中",
    //    "stage.failed": "失敗しました",
    //    "stage.finish": "終了",
    //    "stage.success": "成功",
    //    "stage.inform": "情報収集",
    //},
    //Chinese: {
    //    "login.title": "登录到您的帐户",
    //    "login.email": "电子邮件地址 *",
    //    "login.password": "密码 *",
    //    "login.error": "无效的电子邮件或密码",
    //    "login.signin": "登录",
    //    "login.theme": "主题",
    //    "login.password.placeholder": "您的密码",
    //    "login.logout": "登出",
    //    "menu.title": "体验式AI：拥抱沉浸式学习",
    //    "menu.description": "进入一个新的学习阶段，其中实践是关键。参与引人入胜的体验，以扩展您的理解，安全地扮演角色并应用您的技能。沉浸在基于实践和对成长的热情驱动的互动学习中。",
    //    "chat.conversation": "对话",
    //    "chat.newChat": "新聊天",
    //    "chat.unfortunately": "很遗憾，您未能成功完成资格赛。请重新开始，并尽可能多地了解Charlie，例如他喜欢他的摩托车的什么，他的骑行兴趣和购买动机。您的目标是提供足够的价值，以便他同意看一辆新的2024年摩托车。",
    //    "chat.congratulations": "恭喜！您已成功完成资格赛，有资格进入预赛。请记住您对Charlie的了解，以便在比赛中继续使用这些信息。",
    //    "stage.notstarted": "未开始",
    //    "stage.completed": "已完成",
    //    "stage.inprogress": "进行中",
    //    "stage.failed": "失败",
    //    "stage.finish": "完成",
    //    "stage.success": "成功",
    //    "stage.inform": "信息收集",
    //}
};
